import { Ng2StateDeclaration } from "@uirouter/angular";
import { Ng1StateDeclaration } from "@uirouter/angularjs";
import { modal } from "@gtmhub/core/routing";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { goalFutureStates } from "@webapp/okrs/okrs.future-states";
import { attachNg1StateDependency, featureToggleEnabled } from "../resolve-guards";
import { canViewGoalSuggestions, canViewWhiteboards } from "./resolve-guards/whiteboards.resolve-guards";

export const whiteboardsState = attachNg1StateDependency(
  {
    name: "gtmhub.whiteboards",
    url: "/whiteboards/",
    data: {
      titleKey: "whiteboards",
      trackingPage: "Whiteboards",
      pageBackgroundColor: "#fff",
    },
    params: {
      openTemplates: null,
    },
    component: "whiteboards",
  },
  canViewWhiteboards()
);

export const whiteboardState: ReturnType<typeof attachNg1StateDependency> = attachNg1StateDependency(
  {
    name: "gtmhub.whiteboard",
    url: "/whiteboards/:whiteboardId/",
    params: {
      whiteboardId: null,
      isFirstLoad: null,
    },
    data: {
      trackingPage: "Whiteboard Details",
    },
    component: "whiteboard",
  },
  canViewWhiteboards()
);

const whiteboardsPageState = attachNg1StateDependency(
  {
    name: "gtmhub.whiteboardsV2",
    url: "/whiteboards-v2/",
    data: {
      titleKey: "whiteboards",
      // trackingPage: "Whiteboards",
      pageBackgroundColor: "#fff",
    },
    params: {
      openTemplates: null,
    },
    component: "whiteboards",
  },
  canViewWhiteboards(),
  featureToggleEnabled(FeatureFlag.SingleWhiteboardPageMigrated)
);

const whiteboardPageState = attachNg1StateDependency(
  {
    name: "gtmhub.whiteboardV2",
    url: "/whiteboards-v2/:whiteboardId/",
    data: {
      // trackingPage: "Whiteboard Details",
    },
    component: "whiteboardPageComponent",
  },
  canViewWhiteboards(),
  featureToggleEnabled(FeatureFlag.SingleWhiteboardPageMigrated)
);

export const draftGoalMetricStates = (base: string): Ng1StateDeclaration[] => {
  const draftGoalState = modal({
    name: `${base}.draftGoal`,
    url: "drafts/goals/:goalId/",
    data: {
      trackingPage: "Drafts Goal Details",
    },
    component: "draftGoal",
    params: {
      goalId: null,
    },
    windowClass: "goal-sidebar draft-objective-modal",
  });

  const draftMetricState = modal({
    name: `${draftGoalState.name}.draftMetric`,
    url: "metrics/:metricId/",
    data: {
      trackingPage: "Metric Details",
    },
    component: "draftMetric",
    params: {
      metricId: null,
    },
    windowClass: "sidebar-modal draft-metric-modal",
  });

  return [draftGoalState, draftMetricState];
};

const goalSuggestionsState = attachNg1StateDependency(
  {
    name: "gtmhub.goalSuggestions",
    url: "/goal-suggestions/",
    data: {
      titleKey: "goal_suggestions",
      trackingPage: "Goal Suggestions",
      pageBackgroundColor: "#fff",
    },
    component: "goalSuggestions",
  },
  canViewGoalSuggestions()
);

const goalSuggestionState = attachNg1StateDependency(
  {
    name: "gtmhub.goalSuggestion",
    url: "/goal-suggestions/:goalSuggestionId/",
    data: {
      titleKey: "suggest_goals",
      trackingPage: "Goal Suggestion",
      pageBackgroundColor: "#fff",
    },
    params: {
      goalSuggestionId: null,
    },
    component: "goalSuggestion",
  },
  canViewGoalSuggestions()
);

export const states: (Ng1StateDeclaration | Ng2StateDeclaration)[] = [
  whiteboardsState,
  // whiteboards POC
  whiteboardsPageState,
  whiteboardPageState,
  // ----------------
  whiteboardState,
  ...draftGoalMetricStates(whiteboardState.name),
  ...goalFutureStates(whiteboardState.name),

  goalSuggestionsState,
  goalSuggestionState,
];
