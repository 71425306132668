import { IPromise, IQService, auto } from "angular";
import { StateService } from "@uirouter/angularjs";
import { firstValueFrom } from "rxjs";
import { PromiseInjectable } from "@gtmhub/resolve-guards";
import { PiFeatureVisibilityFacade } from "@webapp/platform-intelligence/shared/services/pi-feature-visibility-facade.service";
import { WhiteboardPermissionsService } from "@webapp/whiteboards/services/whiteboard-permissions.service";

export const canViewWhiteboards = (): PromiseInjectable => [
  "$q",
  "$injector",
  "$state",
  ($q: IQService, $injector: auto.IInjectorService, $state: StateService): IPromise<void> => {
    const whiteboardPermissionsService = $injector.get<WhiteboardPermissionsService>("WhiteboardPermissionsService");
    return $q.when(firstValueFrom(whiteboardPermissionsService.hasPermissionToView$())).then((isAllowedToView) => {
      if (!isAllowedToView) {
        $state.go("default");
        return $q.reject(`User is not allowed to view whiteboards`);
      }
    });
  },
];

export const canViewGoalSuggestions = (): PromiseInjectable => [
  "$q",
  "$injector",
  "$state",
  ($q: IQService, $injector: auto.IInjectorService, $state: StateService): IPromise<void> => {
    const piFeatureVisibilityFacade = $injector.get<PiFeatureVisibilityFacade>("PiFeatureVisibilityFacade");
    return $q.when(firstValueFrom(piFeatureVisibilityFacade.isGuidedOKRsCreationVisible$())).then((isGuidedOKRsCreationVisible) => {
      if (!isGuidedOKRsCreationVisible) {
        $state.go("default");
        return $q.reject(`User is not allowed to view goal suggestions`);
      }
    });
  },
];
