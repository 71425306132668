// eslint-disable-next-line no-restricted-imports
import { auto } from "angular";
import {
  LazyLoadResult,
  NATIVE_INJECTOR_TOKEN,
  Ng2StateDeclaration,
  Resolvable,
  RootModule,
  StateDeclaration,
  StateObject,
  StateQueueManager,
  StateRegistry,
  StatesModule,
  Transition,
  UIROUTER_MODULE_TOKEN,
  UIROUTER_ROOT_MODULE,
  UIRouter,
  _StateDeclaration,
  inArray,
  isFunction,
  isString,
  multiProviderParentChildDelta,
  prop,
  uniqR,
  unnestR,
} from "@uirouter/angular";
// eslint-disable-next-line no-restricted-imports
import { Ng1StateDeclaration } from "@uirouter/angularjs";
import { Injector, NgModuleRef } from "@angular/core";
import { downgradeModule } from "@angular/upgrade/static";
import { noop } from "rxjs";

const INJECTOR_STATE = "__injector_state";

/**
 * UI router does not support child future states and wrongly generate the URL. To solve this we can insert
 * abstract states, but they need to be replaced with a future state before we load the async module.
 *
 * #### Example:
 * ```js
 * // if we have those states
 * const states1 = [
 *     { name: "gtmhub.automation.**", url: "/automation/" },
 *     { name: "gtmhub.automation.history.**", url: "/automation/history/:automationId/" },
 * ];
 *
 * // and try to generate a URL to the second one via either ui-sref directive or $state.href method
 * // we will always hit the first state and the URL will be incomplete
 * console.log($state.href("gtmhub.automation.history", { automationId: "1" })); // will print "/automation/"
 *
 * // To solve this we can register the first state as abstract with its original name
 * const states2 = [
 *     { name: "gtmhub.automation", abstract: true },
 *     { name: "gtmhub.automation.history.**", url: "/automation/history/:automationId/" },
 * ];
 *
 * // then we will have the correct URL
 * ```
 */
export function replaceWithFutureStatesConfig(states: string[]): StatesModule["config"] {
  return (uiRouterInstance: UIRouter): void => {
    const { stateRegistry } = uiRouterInstance;
    for (const state of states) {
      stateRegistry.deregister(state);
      stateRegistry.register({ name: `${state}.**` });
    }
  };
}

let currentlyDowngradedModule: string = undefined;

export const getCurrentlyDowngradedModule = (): string | undefined => currentlyDowngradedModule;

export type LazyNgAndNg1ModuleRef = LazyModuleRef & {
  getNg1Module(): Promise<string>;
};

type LazyModuleRef = {
  lazyState: string;
  createModuleRef(parentInjector: Injector): NgModuleRef<unknown>;
  getModuleInjector(): Injector;
};

const setInjectorToChildStates = (stateRegistry: StateRegistry): void => {
  for (const state of stateRegistry.get()) {
    const injectorState = state.data?.[INJECTOR_STATE];
    if (injectorState) {
      delete state.data[INJECTOR_STATE];

      const injectorResolvable = stateRegistry
        .get(injectorState)
        .$$state()
        .resolvables.find((x) => x.token === NATIVE_INJECTOR_TOKEN);
      state.$$state().resolvables.push(injectorResolvable);
    }
  }
};

StateQueueManager.prototype["registerNoFlush"] = function (this: StateQueueManager, stateDecl: _StateDeclaration): StateObject {
  const queue = this.queue;
  const state = StateObject.create(stateDecl);
  const name = state.name;

  if (!isString(name)) throw new Error("State must have a valid name");
  if (Object.prototype.hasOwnProperty.call(this.states, name) || inArray(queue.map(prop("name")), name)) throw new Error(`State '${name}' is already defined`);

  queue.push(state);

  return state;
};

const applyModuleConfig = (uiRouter: UIRouter, injector: Injector, module: StatesModule = {}): StateObject[] => {
  if (isFunction(module.config)) {
    module.config(uiRouter, injector, module);
  }

  const states = module.states || [];

  const { stateQueue } = uiRouter.stateRegistry;
  const stateObjects = states.map((state) => stateQueue["registerNoFlush"](state));
  stateQueue.flush();
  return stateObjects;
};

// Copied from https://github.com/ui-router/angular/blob/master/src/lazyLoad/lazyLoadNgModule.ts
// Adjusted to call custom applyModuleConfig function
const applyNgModule = (transition: Transition, ng2Module: NgModuleRef<unknown>, parentInjector: Injector, lazyLoadState: StateDeclaration): LazyLoadResult => {
  const injector = ng2Module.injector;
  const uiRouter: UIRouter = injector.get(UIRouter);
  const registry = uiRouter.stateRegistry;

  const originalName = lazyLoadState.name;
  const originalState = registry.get(originalName);
  // Check if it's a future state (ends with .**)
  const isFuture = /^(.*)\.\*\*$/.exec(originalName);
  // Final name (without the .**)
  const replacementName = isFuture && isFuture[1];

  const newRootModules = multiProviderParentChildDelta(parentInjector, injector, UIROUTER_ROOT_MODULE).reduce(uniqR, []) as RootModule[];
  const newChildModules = multiProviderParentChildDelta(parentInjector, injector, UIROUTER_MODULE_TOKEN).reduce(uniqR, []) as StatesModule[];

  if (newRootModules.length) {
    throw new Error("Lazy loaded modules should not contain a UIRouterModule.forRoot() module");
  }

  const newStateObjects: StateObject[] = newChildModules
    .map((module) => applyModuleConfig(uiRouter, injector, module))
    .reduce(unnestR, [])
    .reduce(uniqR, []);

  if (isFuture) {
    const replacementState = registry.get(replacementName);
    if (!replacementState || replacementState === originalState) {
      throw new Error(
        `The Future State named '${originalName}' lazy loaded an NgModule. ` +
          `The lazy loaded NgModule must have a state named '${replacementName}' ` +
          `which replaces the (placeholder) '${originalName}' Future State. ` +
          `Add a '${replacementName}' state to the lazy loaded NgModule ` +
          `using UIRouterModule.forChild({ states: CHILD_STATES }).`
      );
    }
  }

  // Supply the newly loaded states with the Injector from the lazy loaded NgModule.
  // If a tree of states is lazy loaded, only add the injector to the root of the lazy loaded tree.
  // The children will get the injector by resolve inheritance.
  const newParentStates = newStateObjects.filter((state) => !inArray(newStateObjects, state.parent));

  // Add the Injector to the top of the lazy loaded state tree as a resolve
  newParentStates.forEach((state) => state.resolvables?.push(Resolvable.fromData(NATIVE_INJECTOR_TOKEN, injector)));

  return {};
};

export function lazyLoadNgAndNg1Module(
  lazyModuleRefFactory: () => Promise<LazyNgAndNg1ModuleRef>
): (transition: Transition, stateObject: StateDeclaration) => Promise<LazyLoadResult> {
  return (transition: Transition, stateObject: StateDeclaration) => {
    const routerInjector = transition.injector();
    const $injector = routerInjector.get<auto.IInjectorService>("$injector");
    const $ocLazyLoad = routerInjector.get<oc.ILazyLoad>("$ocLazyLoad");
    const injector = routerInjector.get<Injector>(NATIVE_INJECTOR_TOKEN);

    return lazyModuleRefFactory().then((mod) => {
      const moduleRef = mod.createModuleRef(injector);
      const lazyLoadResult = applyNgModule(transition, moduleRef, injector, stateObject);

      const stateRegistry = injector.get(StateRegistry);
      setInjectorToChildStates(stateRegistry);

      const downgradedModule = downgradeModule(() => Promise.resolve(moduleRef));
      currentlyDowngradedModule = downgradedModule;

      return $ocLazyLoad
        .inject(downgradedModule)
        .then(() => mod.getNg1Module())
        .then((ng1Module) => {
          currentlyDowngradedModule = undefined;

          // trigger the completion of the lazy load promise
          $injector.invoke([`$$angularLazyModuleRef${downgradedModule}`, noop]);

          return $ocLazyLoad.inject(ng1Module).then(() => lazyLoadResult);
        });
    });
  };
}

export function lazyLoadNgModule(lazyModuleRefFactory: () => Promise<LazyModuleRef>): (transition: Transition, stateObject: StateDeclaration) => Promise<LazyLoadResult> {
  return (transition: Transition, stateObject: StateDeclaration) => {
    const routerInjector = transition.injector();
    const injector = routerInjector.get<Injector>(NATIVE_INJECTOR_TOKEN);

    return lazyModuleRefFactory().then((mod) => {
      const moduleRef = mod.createModuleRef(injector);
      const lazyLoadResult = applyNgModule(transition, moduleRef, injector, stateObject);

      const stateRegistry = injector.get(StateRegistry);
      setInjectorToChildStates(stateRegistry);

      return lazyLoadResult;
    });
  };
}

type ChildFutureStateRegistration = {
  base: string;
  factory: string;
};

type StatesFactory = (base: string) => (Ng1StateDeclaration | Ng2StateDeclaration)[];

let childFutureStateRegistrations: ChildFutureStateRegistration[] = [];
const childFutureStateFactories: Record<string, StatesFactory> = {};
const stateFactoryToRootModuleState: Record<string, string> = {};

export const registerChildFutureState = (registration: ChildFutureStateRegistration): void => {
  childFutureStateRegistrations.push(registration);
};

/**
 * Registers a set of state factories to be called when lazy-loading other modules.
 *
 * @param factories
 * @param rootModuleState A state that will contain the injector for that module. Usually, all root states do this, so just pass one
 */
export const registerChildFutureStateFactories = (factories: Record<string, StatesFactory>, rootModuleState: string): void => {
  for (const [key, factory] of Object.entries(factories)) {
    childFutureStateFactories[key] = factory;
    stateFactoryToRootModuleState[key] = rootModuleState;
  }
};

const filterRootStates = (states: StateDeclaration[]): StateDeclaration[] =>
  states.reduce<StateDeclaration[]>((result, state) => {
    const nonChildStates = result.filter((x) => !x.name.startsWith(`${state.name}.`));
    if (nonChildStates.length !== result.length) {
      result = nonChildStates;
    }

    if (!result.some((x) => state.name.startsWith(`${x.name}.`))) {
      result.push(state);
    }

    return result;
  }, []);

export function loadChildFutureStatesConfig(uiRouter: UIRouter, injector: Injector, module: StatesModule): void {
  const unprocessedChildFutureStateRegistrations: ChildFutureStateRegistration[] = [];

  for (const registration of childFutureStateRegistrations) {
    const factory = childFutureStateFactories[registration.factory];
    if (!factory) {
      unprocessedChildFutureStateRegistrations.push(registration);
      continue;
    }

    const states = factory(registration.base) as unknown as Ng2StateDeclaration[];

    const rootModuleState = stateFactoryToRootModuleState[registration.factory];
    if (rootModuleState) {
      for (const state of filterRootStates(states)) {
        if (!state.data) {
          state.data = {};
        }
        state.data[INJECTOR_STATE] = rootModuleState;
      }
    }

    module.states.push(...states);
  }

  childFutureStateRegistrations = unprocessedChildFutureStateRegistrations;
}
