import { IQService, auto } from "angular";
import { Ng1StateDeclaration, Ng2StateDeclaration, StateService } from "@uirouter/angularjs";
import { firstValueFrom } from "rxjs";
import { draftGoalMetricStates } from "@gtmhub/whiteboards/whiteboards.states";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { goalFutureStates } from "@webapp/okrs/okrs.future-states";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { attachNg1StateDependency, featureToggleDisabled, featureToggleEnabled } from "../resolve-guards";

const guards = [
  [
    "$q",
    "$injector",
    "$state",
    ($q: IQService, $injector: auto.IInjectorService, $state: StateService) => {
      const editionFeatureService = $injector.get<EditionFeatureService>("EditionFeatureService");
      const permissionsFacade = $injector.get<PermissionsFacade>("PermissionsFacade");

      return $q
        .when($q.all([firstValueFrom(editionFeatureService.hasFeature$("strategies")), firstValueFrom(permissionsFacade.hasPermission$("AccessStrategies"))]))
        .then(([hasFeature, hasPermission]) => {
          const hasAccess = hasFeature && hasPermission;
          if (!hasAccess) {
            $state.go("default");
            return $q.reject(`User is not allowed to view decisions`);
          }
        });
    },
  ],
];

const decisionsPageState = attachNg1StateDependency(
  {
    name: "gtmhub.decisions",
    url: "/decisions/",
    data: {
      requiresLogin: true,
      titleKey: "Decisions",
      trackingPage: "Manage decisions",
    },
    component: "decisionsPageComponent",
  },
  ...guards,
  featureToggleDisabled(FeatureFlag.DecisionsV2)
);

const detailedStrategyPage = attachNg1StateDependency(
  {
    name: "gtmhub.decision",
    url: "/decision/:betId/",
    data: {
      requiresLogin: true,
      titleKey: "Detailed Strategy",
      trackingPage: "Evaluate a strategic bet with Quantive Assistant",
      pageBackgroundColor: "#fff",
    },
    params: {
      betId: null,
    },
    component: "evaluateBetPageComponent",
  },
  ...guards,
  featureToggleDisabled(FeatureFlag.DecisionsV2)
);

const decisionMapHypothesisPageState: Ng1StateDeclaration = attachNg1StateDependency(
  {
    name: "gtmhub.decision.hypothesis",
    url: "area/:areaId/hypothesis/:hypothesisId/",
    data: {
      requiresLogin: true,
      titleKey: "Decision Map Hypothesis",
      trackingPage: "Selected hypothesis",
      pageBackgroundColor: "#fff",
    },
    params: {
      betId: null,
      areaId: null,
      hypothesisId: null,
    },
    views: {
      "@gtmhub": {
        component: "generateStrategyPageComponent",
      },
    },
  },
  ...guards,
  featureToggleDisabled(FeatureFlag.DecisionsV2)
);

const decisionMapSummaryPageState: Ng1StateDeclaration = attachNg1StateDependency(
  {
    name: "gtmhub.decision.decisionMapSummary",
    url: "decision-map-summary/",
    data: {
      requiresLogin: true,
      titleKey: "Decision Map Summary",
      trackingPage: "Generate a decision map summary",
      pageBackgroundColor: "#fff",
    },
    views: {
      "@gtmhub": {
        component: "decisionMapSummaryPageComponent",
      },
    },
  },
  ...guards,
  featureToggleDisabled(FeatureFlag.DecisionsV2)
);

const detailedStrategyNewPageState = attachNg1StateDependency(
  {
    name: "gtmhub.decisionNew",
    url: "/create-decision/",
    data: {
      requiresLogin: true,
      titleKey: "Detailed Strategy",
      trackingPage: "Create a strategic bet",
      pageBackgroundColor: "#fff",
    },
    component: "detailedStrategyPageComponent",
  },
  ...guards,
  featureToggleDisabled(FeatureFlag.DecisionsV2)
);

const decisionV2NewPageState = attachNg1StateDependency(
  {
    name: "gtmhub.decisionNewV2",
    url: "/create-decision-v2/",
    data: {
      requiresLogin: true,
      titleKey: "Create Decision",
      trackingPage: "Create a decision",
      pageBackgroundColor: "#fff",
    },
    component: "decisionNewV2PageComponent",
  },
  ...guards,
  featureToggleEnabled(FeatureFlag.DecisionsV2)
);

const decisionV2PageState = attachNg1StateDependency(
  {
    name: "gtmhub.decisionV2",
    url: "/decision-v2/:decisionId/",
    data: {
      requiresLogin: true,
      titleKey: "Decision",
      trackingPage: "View decision",
      pageBackgroundColor: "#fff",
    },
    params: {
      decisionId: null,
    },
    component: "decisionV2PageComponent",
  },
  ...guards,
  featureToggleEnabled(FeatureFlag.DecisionsV2)
);

export const states: (Ng1StateDeclaration | Ng2StateDeclaration)[] = [
  decisionsPageState,
  detailedStrategyNewPageState,
  detailedStrategyPage,

  decisionMapSummaryPageState,
  decisionMapHypothesisPageState,

  decisionV2NewPageState,
  decisionV2PageState,
  ...draftGoalMetricStates(decisionV2PageState.name),
  ...goalFutureStates(decisionV2PageState.name),
];
