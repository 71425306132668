import { isActiveSession } from "@gtmhub/sessions/redux/session-selectors";
import { CustomFieldVisualizationKind, ICustomField } from "@webapp/custom-fields/models/custom-fields.models";
import { localize } from "@webapp/localization/utils/localization.utils";
import { Session } from "@webapp/sessions/models/sessions.model";
import { GridColumnConfig, OkrsGridColumnField, PersistedGridConfig } from "../models/grid.models";

const CUSTOM_FIELD_PREFIX = "customFields.";

export const OKRS_GRID_COLUMNS = new Map<OkrsGridColumnField, GridColumnConfig>([
  [
    "name",
    {
      field: "name",
      title: localize("OKRs"),
      width: 360,
      minResizableWidth: 80,
      sortable: true,
      lockable: false,
      locked: true,
      includeInChooser: false,
      reorderable: false,
      hidden: false,
    },
  ],
  [
    "assignees.name",
    {
      // the field value equals to what is expected by the BE to sort by owners
      field: "assignees.name",
      title: localize("owner_cap"),
      width: 128,
      minResizableWidth: 80,
      sortable: true,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: false,
    },
  ],
  [
    "confidence",
    {
      field: "confidence",
      title: localize("confidence"),
      width: 100,
      minResizableWidth: 80,
      sortable: false,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: false,
    },
  ],
  [
    "attainment",
    {
      field: "attainment",
      title: localize("progress_cap"),
      width: 200,
      minResizableWidth: 100,
      sortable: true,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: false,
      cellAlign: "right",
    },
  ],
  [
    "tags",
    {
      field: "tags",
      title: localize("tags"),
      width: 200,
      minResizableWidth: 80,
      sortable: false,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: false,
    },
  ],
  [
    "description",
    {
      field: "description",
      title: localize("description"),
      width: 200,
      minResizableWidth: 80,
      sortable: true,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: false,
    },
  ],
  [
    "workflowStatus",
    {
      field: "workflowStatus",
      title: localize("status"),
      width: 40,
      minResizableWidth: 40,
      sortable: false,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
    },
  ],
  [
    "designScore.totalPoints",
    {
      // the field value equals to what is expected by the BE to sort by design score
      field: "designScore.totalPoints",
      title: localize("design_score_cap"),
      width: 60,
      minResizableWidth: 60,
      sortable: true,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
      cellAlign: "right",
    },
  ],
  [
    "closedStatus.status",
    {
      field: "closedStatus.status",
      title: localize("okr_state"),
      width: 200,
      minResizableWidth: 80,
      sortable: false,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
    },
  ],
  [
    "sessionId",
    {
      field: "sessionId",
      title: localize("session"),
      width: 360,
      minResizableWidth: 80,
      sortable: true,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
    },
  ],
  [
    "relatedItems",
    {
      field: "relatedItems",
      title: localize("related_items"),
      width: 200,
      minResizableWidth: 80,
      sortable: false,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
    },
  ],
  [
    "actual",
    {
      field: "actual",
      title: localize("actual"),
      width: 104,
      minResizableWidth: 80,
      sortable: false,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
      cellAlign: "right",
    },
  ],
  [
    "target",
    {
      field: "target",
      title: localize("target"),
      width: 104,
      minResizableWidth: 80,
      sortable: false,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
      cellAlign: "right",
    },
  ],
  [
    "lastUpdatedAt",
    {
      field: "lastUpdatedAt",
      title: localize("last_update_date"),
      width: 126,
      minResizableWidth: 104,
      sortable: false,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
      cellAlign: "left",
    },
  ],
  [
    "lastUpdatedComment",
    {
      field: "lastUpdatedComment",
      title: localize("last_update_note"),
      width: 126,
      minResizableWidth: 104,
      sortable: false,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
      cellAlign: "left",
    },
  ],
]);

const OKRS_GRID_COLUMN_CUSTOM_FIELDS = new Map<CustomFieldVisualizationKind, Omit<GridColumnConfig, "title" | "field">>([
  [
    "gtmhub.multiselect",
    {
      width: 200,
      minResizableWidth: 80,
      sortable: false,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
      cellAlign: "left",
    },
  ],
  [
    "gtmhub.selectassignee",
    {
      width: 128,
      minResizableWidth: 80,
      sortable: false,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
      cellAlign: "left",
    },
  ],
  [
    "gtmhub.select",
    {
      width: 200,
      minResizableWidth: 80,
      sortable: true,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
      cellAlign: "left",
    },
  ],
  [
    "gtmhub.datepicker",
    {
      width: 104,
      minResizableWidth: 80,
      sortable: true,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
      cellAlign: "left",
    },
  ],
  [
    "gtmhub.numeric",
    {
      width: 104,
      minResizableWidth: 80,
      sortable: true,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
      cellAlign: "right",
    },
  ],
]);

export const shouldDisplayWorkflowColumnFromSessions = (sessionIds: string[], sessionsMap: Map<string, Session>): boolean => {
  for (const s of sessionIds) {
    if (sessionsMap.get(s)?.workflow) {
      return true;
    }
  }

  return false;
};

export function getDefaultGridConfig(): PersistedGridConfig {
  return {
    columnsConfig: Array.from(OKRS_GRID_COLUMNS.values())
      .filter((column) => !column.hidden)
      .map(({ field, width }) => ({ field, width })),
    state: { skip: 0, take: 20 },
  };
}

export function getDefaultColumnWidth(field: OkrsGridColumnField | CustomFieldVisualizationKind): number {
  const column = OKRS_GRID_COLUMNS.get(field as OkrsGridColumnField) ?? OKRS_GRID_COLUMN_CUSTOM_FIELDS.get(field as CustomFieldVisualizationKind);
  return column ? column.width : 100;
}

export function generateCustomFieldColumns(customFieldsForSession: ICustomField[]): GridColumnConfig[] {
  return (customFieldsForSession || []).map((customField, index): GridColumnConfig => {
    const customFieldColumnConfig = OKRS_GRID_COLUMN_CUSTOM_FIELDS.get(customField.visualizationKind) || {
      width: 200,
      minResizableWidth: 100,
      sortable: true,
      lockable: true,
      locked: false,
      includeInChooser: true,
      reorderable: true,
      hidden: true,
      cellAlign: "left",
    };

    return {
      field: `${CUSTOM_FIELD_PREFIX}${customField.name}.${index}`,
      title: customField.label,
      customFieldVisualizationKind: customField.visualizationKind,
      customFieldName: customField.name,
      customFieldPredefinedValues: customField?.settings?.predefinedValues,
      customFieldFormatting: customField?.settings?.formatting,
      ...customFieldColumnConfig,
    };
  });
}

// (Rusi, Ivan, Iva) to do: check and change (if needed) the session ids consuming logic when the "not" operator logic is approved
export function getCustomFieldsForSessionIds(sessionIds: string[], goalCustomFields: ICustomField[], sessionsMap?: Map<string, Session>): ICustomField[] {
  if (!sessionIds?.length || sessionIds.includes("all-okrs")) {
    return goalCustomFields || [];
  }

  if (sessionIds.includes("activeSessions") && sessionsMap) {
    return (goalCustomFields || []).filter(
      (customField) =>
        customField.operationalType === "global" ||
        customField.targetIds?.some((targetId) => {
          const session = sessionsMap.get(targetId);
          return session && isActiveSession(session);
        })
    );
  }

  return (goalCustomFields || []).filter(
    (customField) => customField.operationalType === "global" || customField.targetIds?.some((targetId) => sessionIds.includes(targetId))
  );
}
