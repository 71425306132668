import { monthPattern, quarterPattern, weekPattern, yearPattern } from "@gtmhub/kpis/util";
import { localize } from "@gtmhub/localization";
import { GroupedSnapshots, KpiSnapshotUI, PeriodKey } from "@webapp/kpis/models/kpi-snapshot.models";
import dayjs from "@webapp/shared/libs/dayjs";

export function getCadenceKey(dateString: string, periodKey: PeriodKey): string {
  const [year, month, day] = dateString.split("-").map((part) => parseInt(part, 10));
  const date = new Date(year, month - 1, day);
  let weekNumber;
  let quarter;
  let isoWeekYear;

  switch (periodKey) {
    case "daily":
      return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;

    case "weekly":
      weekNumber = dayjs(date).isoWeek();
      isoWeekYear = dayjs(date).isoWeekYear();
      return `${isoWeekYear}-W${String(weekNumber).padStart(2, "0")}`;

    case "monthly":
      return `${year}-${String(month).padStart(2, "0")}`;

    case "quarterly":
      quarter = Math.floor((month - 1) / 3) + 1;
      return `${year}-Q${quarter}`;

    case "yearly":
      return `${year}`;

    default:
      throw new Error("Invalid period key");
  }
}

export const getStartDate = (cadence: string, dateFormat: string = "YYYY-MM-DD"): string => {
  const quarterMatches = cadence.match(quarterPattern);
  if (quarterMatches) {
    const date = dayjs(`${quarterMatches[1]}-01-01`).quarter(+quarterMatches[2]);
    return date.startOf("quarter").format(dateFormat);
  }

  const weekMatches = cadence.match(weekPattern);
  if (weekMatches) {
    const date = dayjs(`${weekMatches[1]}-01-01`).isoWeek(+weekMatches[2]);
    return date.startOf("isoWeek").format(dateFormat);
  }

  if (yearPattern.test(cadence)) {
    const date = dayjs(`${cadence}-01-01`);
    return date.startOf("year").format(dateFormat);
  }

  if (monthPattern.test(cadence)) {
    const date = dayjs(`${cadence}-01`);
    return date.startOf("month").format(dateFormat);
  }

  const date = dayjs(cadence);
  return date.startOf("day").format(dateFormat);
};

export function getPeriodKey(snapshotKey: string, period: PeriodKey): string {
  const dateString = getStartDate(snapshotKey);
  const [year, month, day] = dateString.split("-").map((part) => parseInt(part, 10));
  const date = new Date(year, month - 1, day);

  const monthNames = [
    localize("january"),
    localize("february"),
    localize("march"),
    localize("april"),
    localize("may"),
    localize("june"),
    localize("july"),
    localize("august"),
    localize("september"),
    localize("october"),
    localize("november"),
    localize("december"),
  ];

  switch (period) {
    case "weekly": {
      const day = date.getDay();
      const diff = date.getDate() - day + (day === 0 ? -6 : 1);
      const startOfWeek = new Date(date.setDate(diff));
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);
      return `${startOfWeek.getDate()} ${monthNames[startOfWeek.getMonth()]} - ${endOfWeek.getDate()} ${monthNames[endOfWeek.getMonth()]} ${endOfWeek.getFullYear()}`;
    }
    case "monthly":
      return `${monthNames[date.getMonth()]} ${date.getFullYear()}`;

    case "quarterly": {
      const quarterStartMonth = Math.floor(date.getMonth() / 3) * 3;
      const quarterEndMonth = quarterStartMonth + 2;
      const startOfQuarter = new Date(date.getFullYear(), quarterStartMonth, 1);
      const endOfQuarter = new Date(date.getFullYear(), quarterEndMonth + 1, 0);
      return `${startOfQuarter.getDate()} ${monthNames[startOfQuarter.getMonth()]} - ${endOfQuarter.getDate()} ${monthNames[endOfQuarter.getMonth()]} ${date.getFullYear()}`;
    }
    case "yearly":
      return `${date.getFullYear()}`;

    default:
      return date.toISOString().split("T")[0];
  }
}

export function snapshotToGroupedSnapshot(snapshot: KpiSnapshotUI, periodKey: PeriodKey): GroupedSnapshots {
  return {
    ...snapshot,
    period: getPeriodKey(snapshot.key, periodKey),
    cadenceKey: getCadenceKey(getStartDate(snapshot.key), periodKey),
    startDate: getStartDate(snapshot.key),
  };
}

export function formatDateWithTimezone(date: Date): string {
  const timeZoneOffset = date.getTimezoneOffset();
  const offsetHours = Math.abs(timeZoneOffset / 60);
  const offsetSign = timeZoneOffset > 0 ? "-" : "+";
  const offsetString = `UTC${offsetSign}${offsetHours}`;

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}-${offsetString}`;
}
