import { State } from "@progress/kendo-data-query";
import { IGridFilter, OrFiltersGroup } from "@gtmhub/shared/components/grid-filtering-bar";
import { ICustomField } from "@webapp/custom-fields/models/custom-fields.models";
import { ShareableFilter } from "@webapp/shared/unified-filters/models/shareable-filters.models";
import { ExtendedArrayGridFilter, ExtendedGridFilter, ExtendedOrFilterGroups, FilterFieldName } from "./models";
import { GridColumnConfig, GridConfig, PersistedGridColumn } from "./okrs-grid/models/grid.models";
import { getDefaultGridConfig } from "./okrs-grid/utils/column.utils";
import { generateFilterFromCustomField, generateFilterFromFilterKey } from "./shared/utils/utils";

/**
 * `CompatibilityValidator` is a function which will check if a said filter is compatible between quick and advanced filters
 */
type CompatibilityValidator = (filter: ExtendedOrFilterGroups) => boolean;

const filterSpecificCompatibilityFuncs: { [key in FilterFieldName]?: CompatibilityValidator } = {
  attainment: (filter: ExtendedOrFilterGroups): boolean => {
    if (filter.operator === "greater" && filter.value === 0.7) {
      return true;
    }

    if (filter.operator === "less") {
      return filter.value === 0 || filter.value === 0.2 || filter.value === 0.4 || filter.value === 0.7;
    }

    return false;
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "designScore.totalPoints": (filter: ExtendedOrFilterGroups): boolean => {
    if (filter.operator === "greater" && filter.value === 70) {
      return true;
    }

    if (filter.operator === "less") {
      return filter.value === 0 || filter.value === 20 || filter.value === 40 || filter.value === 70;
    }

    return false;
  },
};

function hasDuplicates(keys: string[]): boolean {
  return new Set(keys).size !== keys.length;
}

/**
 *
 * @returns false when:
 * more than one OR group in the filter
 * more than one unique selector in the group
 * `CompatibilityValidator` function is implemented for a filter and returns false
 * OR filter operators are not the default ones we use in quick filters
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const areQuickFiltersCompatible = (groups: ExtendedOrFilterGroups[][], customFields: ICustomField[]): boolean => {
  if (!groups) {
    return true;
  }

  if (groups.length > 1) {
    return false;
  }

  const firstGroupFilters = groups[0];
  if (!firstGroupFilters || firstGroupFilters.length == 0) {
    return true;
  }

  const filterKeys = firstGroupFilters.map((filter) => filter.fieldName);

  if (hasDuplicates(filterKeys)) {
    return false;
  }

  const compatibleCFNames = getQuickOkrFilterCompatibleCustomFields(customFields).map((cf) => cf.name);

  for (const filter of firstGroupFilters) {
    const customField = customFields.find((cf) => cf.name === filter.fieldName);
    if (customField && !compatibleCFNames.includes(filter.fieldName)) {
      return false;
    }

    // `CompatibilityValidator` should take precedence before checking operator's matching default operator
    // in each validator's implementation the checking of operator values should be taken into account
    const compatibilityFunc = filterSpecificCompatibilityFuncs[filter.fieldName];
    if (compatibilityFunc) {
      if (!compatibilityFunc(filter)) {
        return false;
      }

      continue;
    }

    // create a new filter from the same field name and check if default operators are matched
    const newFilter = customField ? generateFilterFromCustomField(filter.fieldName, customField) : generateFilterFromFilterKey(filter.fieldName);
    if (!newFilter) {
      continue;
    }

    if (filter.operator !== newFilter.operator) {
      return false;
    }
  }

  return true;
};

export const convertOrGroupToExtendedFilterGroup = (groups: OrFiltersGroup[], customFields: ICustomField[] = []): ExtendedOrFilterGroups[][] => {
  return groups.map((group) => {
    return group.map((filter) => {
      // TODO: handle separately cases with dynamic values from ownerIds

      const customField = customFields.find((cf) => cf.name === filter.fieldName);
      const generatedFilter = customField
        ? generateFilterFromCustomField(filter.fieldName as FilterFieldName, customField)
        : generateFilterFromFilterKey(filter.fieldName as FilterFieldName);

      return { ...generatedFilter, ...filter } as ExtendedGridFilter;
    });
  });
};

export const extractSessionIdsFromFilterGroups = (groups: ExtendedOrFilterGroups[][]): string[] => {
  return groups
    .map((group) => {
      return group
        .filter((filter) => filter.fieldName === "sessionId" && filter.value)
        .map((filter: ExtendedArrayGridFilter) => filter.value)
        .flat();
    })
    .flat();
};

export const filterSessionCustomFields = (sessionIds: string[], customFields: ICustomField[]): ICustomField[] => {
  return customFields.filter((cf) => {
    if (cf.targetIds?.length) {
      return cf.targetIds.some((id) => sessionIds.includes(id));
    }

    return cf;
  });
};

export const getQuickOkrFilterCompatibleCustomFields = (customFields: ICustomField[]): ICustomField[] => {
  return customFields.filter(
    (cf) => cf.visualizationKind === "gtmhub.select" || cf.visualizationKind === "gtmhub.multiselect" || cf.visualizationKind === "gtmhub.selectassignee"
  );
};

export const getDefaultFilters = (planningSessionId: string): ExtendedOrFilterGroups[] => {
  return planningSessionId
    ? [{ ...generateFilterFromFilterKey("sessionId"), value: [planningSessionId] }, generateFilterFromFilterKey("ownerIds")]
    : [{ ...generateFilterFromFilterKey("sessionId"), dynamicValues: [{ type: "activeSessions" }] }];
};

// TODO: this is a temp fix for being able to save OKR views, we should refactor the filters not to include the deleted properties below
// https://quantive-inc.atlassian.net/browse/GVS-60625
export const toOrFilterGroups = (orFilterGroups: ExtendedOrFilterGroups[][]): OrFiltersGroup[] => {
  return orFilterGroups.map((group) => {
    return group.map(({ operators, label, settings, filterKey, visualizationKind, ...rest }) => rest as IGridFilter);
  });
};

export const getTextCustomFieldNames = (customFields: ICustomField[]): string[] => {
  return customFields.reduce((names, cf) => {
    if (cf.visualizationKind !== "gtmhub.text" && cf.visualizationKind !== "gtmhub.textarea") {
      return names;
    }

    if (cf.targetTypes.includes("goal") || cf.targetTypes.includes("metric")) {
      names.push(cf.name);
    }

    return names;
  }, []);
};

export const areThereFiltersWithValues = (groups: ExtendedOrFilterGroups[][]): boolean => {
  if (!groups) {
    return false;
  }

  return groups.some((filters) =>
    filters.some((filter) => (Array.isArray(filter.value) && filter.value.length) || (Array.isArray(filter.dynamicValues) && filter.dynamicValues.length))
  );
};

export const patchEmptyViewFilterGroups = (view: ShareableFilter): ShareableFilter => {
  if (!view.filterGroups || view.filterGroups.length === 0) {
    view.filterGroups = [[]];
  }

  return view;
};

export function prepareGridColumnsConfig(
  columns: PersistedGridColumn[],
  existingGridConfig: GridConfig,
  customFieldColumns: GridColumnConfig[],
  defaultColumns: GridColumnConfig[]
): { config: { state: State; columnsConfig: PersistedGridColumn[] }; allColumns: GridColumnConfig[] } {
  // 1. Create the base config using passed columns or default
  const config = columns
    ? {
        state: existingGridConfig?.state || { skip: 0, take: 20 },
        columnsConfig: columns,
      }
    : getDefaultGridConfig();

  // 2. Find existing custom field columns from current grid config that aren't in the new customFieldColumns
  const existingCustomFieldColumns =
    existingGridConfig?.columnsConfig?.filter((col) => col.field?.startsWith("customFields.") && !customFieldColumns?.some((newCol) => newCol.field === col.field)) || [];

  // 3. Combine all columns to ensure none are lost during filtering
  const allColumns = [...(customFieldColumns || []), ...existingCustomFieldColumns, ...defaultColumns];

  return { config, allColumns };
}
